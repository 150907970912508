<template>
 <div>
  <vx-card>

    <div class="my-5 text-center gap-2 flex">
        <!-- <input type="text" name="from" id="from" placeholder="از تاریخ" v-model="fromDate" class="border-0 rounded bg-grey-light px-2 py-2 text-black"> -->
        <!-- <input type="text" name="from" id="from" placeholder="تا تاریخ" v-model="toDate" class="border-0 rounded bg-grey-light px-2 py-2 text-black"> -->



        <vue-button-spinner
          :is-loading="isLoading"
          :disabled="isLoading"
          >
          <span @click="getIps" >
          به روزرسانی آی پیها
          </span>

        </vue-button-spinner>
    </div>
    <EventModal
          v-show="isModalVisible"
          @close="closeModal"
          @reloadevents="getIps"
          :ip_address=this.setIpForModal
        />
    <div v-if="isData">
      <vs-pagination class="my-3" :total="page_count" v-model="page"></vs-pagination>
        مجموع آی‌پی‌ها: {{ this.total_items }}
        <div class="flex text-white bg-primary py-4 px-4 rounded my-2 text-sm text-center" >
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> آی‌پی </span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> شماره ماشین </span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> شماره نود </span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> وضعیت ماشین </span>
            <span class="w-64 border-solid border-0 border-r border-white border-opacity-25 "> فیلتر </span>
            <span class="w-64 border-solid border-0 border-r border-white border-opacity-25 "> فعالسازی آی پی</span>
            <span class="w-64 border-solid border-0 border-r border-white border-opacity-25 "> عملیات</span>


        </div>
        <div class="flex text-white bg-primary py-4 px-4 rounded my-2 text-sm text-center" :key="ip.vm_id" v-for="ip in ips">
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> {{ ip.ip_address }} </span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> {{ ip.vm_sequence_id }} </span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> {{ ip.node_id }} </span>
            <span class="w-32 border-solid border-0 border-r border-white border-opacity-25 "> {{ ip.vm_status_title }} </span>
            <span dir="ltr" class="w-64 border-solid border-0 border-r border-white border-opacity-25 ">  <vue-button-spinner
          >
          <span @click="showModal(ip.ip_address)"  class="text-sm">
          اعلام فیلتری
          </span>

        </vue-button-spinner>

</span>
<span class="w-64 border-solid border-0 border-r border-white border-opacity-25 " >
        <vs-button @click="activePrompt = true; myip= ip.ip_address; myvmnumber = ip.vm_sequence_id" class="bg-success text-sm " color="primary" type="border">در مدار ساخت قرار بده</vs-button>

        <vs-prompt
          title="در مدار قرار دادن آی پی"
          @accept="deleteVm(myvmnumber)"
          :active.sync="activePrompt"
          @close="activePrompt = false "
          >

          <div class="con-exemple-prompt">
            آیا برای در مدار قرار دادن آی پی {{ myip }} مطمئن هستید؟


          </div>
        </vs-prompt>
      </span>
      <span>

        <vue-button-spinner
          v-if="ip.vm_status == 5"
          class="btn bg-warning"
          >
          <span @click="startVM(ip.vm_sequence_id)"  class="text-sm">
          روشن کن
          </span>

        </vue-button-spinner>

        <vs-button
          :href="'https://check-host.net/check-tcp?host='+ip.ip_address+':2280'"
          target="_blank"
          v-if="ip.vm_status == 4"
          class="btn bg-danger"
          >
          تست پورت ssh

        </vs-button>
        <vs-button
          :href="'https://check-host.net/check-ping?host='+ip.ip_address"
          target="_blank"
          v-if="ip.vm_status == 4"
          class="btn bg-blue"
          >
          <span @click="startVM(ip.vm_sequence_id)"  class="text-sm">
          ping
          </span>

        </vs-button>
      </span>


        </div>
        <vs-pagination class="my-3" :total="page_count" v-model="page"></vs-pagination>

    </div>

</vx-card>
<div v-if="isVisible" class="loader-overlay">
        <div class="loader"></div>
        <span class="text" v-html="text"></span>
    </div>
 </div>



</template>

<script>
import VueButtonSpinner from 'vue-button-spinner'
import EventModal from './IpFilterModal.vue';

    export default {
        name: "Loader",
        // props: {
        //     isVisible: {type: Boolean, required: true},
        //     text: {type: String, required: false, default: ""},
        // },
        components: {
          VueButtonSpinner,
          EventModal
        },
        data: () => ({
            fromDate:'',
            toDate:'',
            ips:[],
            myip: "",
            myvmnumber: "",
            isData:false,
            isLoading: false,
            isVisible: false,
            text: 'هایو',
            status: '',
            isModalVisible: false,
            page_count: 0,
            page: 1,
            total_items: '',
            reloadme: 0,
            setIpForModal: "",
            activePrompt:false,

        }),
        watch: {
          page() {
            this.getEvents()
          },
          reloadme() {
            this.getEvents()
          }

        },
        methods: {
            showModal(ip) {
              this.setIpForModal = ip
              this.isModalVisible = true;
            },
            closeModal() {
              this.isModalVisible = false;
            },
            getIps() {
                 this.isLoading = true
                 this.$http.get(
                    '/admin/cloud/ip/mohsen/list?location='
                 ).then((res) => {
                    this.total_items = res.data.params.total_items
                    this.ips = res.data.params.data;
                    this.isData = true
                    this.page_count = res.data.params.last_page
                    this.isLoading = false
                    this.status = false // or success
                 }).catch(error => {
                    this.$vs.notify({
                      text: error.response.data.message,
                      color: "danger",
                    });
                    this.isLoading = false
                    this.status = true //or error
                 })
            },
            startVM(id) {
              this.isVisible = true

              this.$http
                .get(`/admin/cloud/vm/${id}/reboot`)
                .then((res) => {
                  this.$vs.notify({ text: res.data.message });
                  this.getIps()
                  this.isVisible = false

                })
                .catch((err) => {
                  this.$vs.notify({ text: err.response.data.message });
                });
            },
            deleteVm(id) {
                  // do the thing needing confirming
              this.isVisible = true

              this.$http
                .post(`/admin/cloud/vm/${id}/delete`)
                .then((res) => {
                  this.$vs.notify({ text: res.data.message });
                  this.getIps()
                  this.isVisible = false

                })
                .catch((err) => {
                  this.$vs.notify({ text: err.response.data.message });
                });

            },
        },

        created() {
          this.getIps()
        }

    }
</script>
<style lang="scss">
    .loader-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 999;
        cursor: pointer;
        span.text {
            display: inline-block;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            color: #fff;
        }
        .loader {
            animation: loader-animate 1.5s linear infinite;
            clip: rect(0, 80px, 80px, 40px);
            height: 80px;
            width: 80px;
            position: absolute;
            left: calc(50% - 40px);
            top: calc(50% - 40px);
            &:after {
                animation: loader-animate-after 1.5s ease-in-out infinite;
                clip: rect(0, 80px, 80px, 40px);
                content: '';
                border-radius: 50%;
                height: 80px;
                width: 80px;
                position: absolute;
            }
        }
        @keyframes loader-animate {
            0% {
                transform: rotate(0deg)
            }
            100% {
                transform: rotate(220deg)
            }
        }
        @keyframes loader-animate-after {
            0% {
                box-shadow: inset #fff 0 0 0 17px;
                transform: rotate(-140deg);
            }
            50% {
                box-shadow: inset #fff 0 0 0 2px;
            }
            100% {
                box-shadow: inset #fff 0 0 0 17px;
                transform: rotate(140deg);
            }
        }
    }
</style>

<template>
  <modal
      mytitle="اعلام فیلتری"
      @close="$emit('close')">
      <div slot="body">
          <form @submit.prevent="onSubmit">
              <!-- Name -->
              <div class="vx-row justify-center">
                <div class="form-item" :class="{ errorInput: $v.ip_address.$error }">
                    <div class="flex flex-col">
                    آدرس آی‌پی
                    <p class="errorText" v-if="!$v.ip_address.required"> پر کردن عنوان اجباری است</p>
                    </div>
                    <div class="flex flex-col">
                    <input
                        dir="ltr"
                        v-model="ip_address"
                        :class="{ error: $v.ip_address.$error }+' border-0 rounded bg-grey-light px-2 py-2 text-black'"
                        @change="$v.ip_address.$touch()">
                        </div>
                </div>
              </div>
              <div class="vx-row justify-center">
                <div class="form-item" >


                    <div class="flex flex-col">
                    توضیحات
                    </div>

                    <div class="flex flex-col">
                    <textarea
                        v-model="description"
                        class=' border-0 rounded bg-grey-light px-2 py-2 text-black'
                        ></textarea>
                        </div>
                </div>
              </div>


              <br>
              <!-- Button -->
              <vue-button-spinner
                :is-loading="isLoading"
                :disabled="isLoading"
                class="btn px-6 py-2 bg-secondary rounded border-0 text-white"
                >
                <span @click="onSubmit" >
                ثبت
                </span>

              </vue-button-spinner>
              <!-- <button class="btn px-6 py-2 bg-secondary rounded border-0 text-white">ثبت</button> -->
          </form>
      </div>
  </modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import VueButtonSpinner from 'vue-button-spinner'

import modal from './Modal.vue'
export default {
  components: {
      modal,
      VueButtonSpinner
  },
  props: {
    ip_address: ""
  },
  watch: {

  },
  data () {
      return {
          // ip_address: '',
          description: '',
          isLoading: false,
          status: ''
      }
  },
  validations: {
      ip_address: {
          required
      }



  },
  methods: {
      addZero(i) {
        if (i < 10) {i = "0" + i}
        return i;
      },
      onSubmit () {
          this.$v.$touch()
          if(!this.$v.$invalid) {
              const user = {
                  ip_address: this.ip_address,
                  description: this.description,
                  is_filter: 1
              }
              this.isLoading = true
              this.$http.post(
                '/admin/cloud/ip/set/action', user
              ).then((res) => {
                this.isLoading = false
                this.status = false // or success
                // this.logs = res.data.params.data;
                this.isData = true
                this.$vs.notify({
                  text: res.data.message,
                  color: "success",
                });
                this.$emit('reloadevents')
              }).catch(error => {
                this.$vs.notify({
                  text: error.response.data.message,
                  color: "danger",
                });
                this.isLoading = false
                this.status = true //or error
              })

              //DONE
              this.ip_address = ''
              this.description = ''
              this.$v.$reset()
              this.$emit('close')
          }
      }
  }
}
</script>

<style lang="scss">
.theme-dark form  {
  color: #000000 !important;
}
.form-item .errorText {
  display: none;
  margin-bottom: 8px;
  font-size: 13.4px;
  color: red;
}

.form-item {
  &.errorInput .errorText {
      display: block;
  }
}

input.error {
  border-color: red;
}
</style>
